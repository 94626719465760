import React from 'react';

import './app.css'
import {Navigate, Route, Routes} from "react-router-dom";
import Shipping from "./shipping/Shipping";
import Shipment from "./shipping/Shipment";


export default function MyRoutes(props) {

    return (
        <Routes>
            <Route path='/' exact element={<Shipping/>}></Route>
            <Route path='/track/:token' exact element={<Shipment/>}></Route>
            <Route path='/track/:token/:zip' exact element={<Shipment/>}></Route>
            <Route path='*' element={<Navigate to="/" />} ></Route>

        </Routes>
    )
}


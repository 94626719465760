import React from "react";
import TimelineContent from '@mui/lab/TimelineContent';
import Typography from "@mui/material/Typography";
import moment from "moment";
import {Divider, Stack} from "@mui/material";
import Box from "@mui/material/Box";

export default function ZiggyEvent(props) {

    const date = props.date !== null ? moment.utc(new Date(moment.unix(props.date))).format("MMM Do YYYY") : ""

    return <><Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>


            <Box color={date !== "not available" ? "primary" : 'lightgrey'}>
                {props.children}
            </Box>
            <TimelineContent
                sx={{
                    textAlign: 'center',
                    minWidth: props.minWidth || 120,
                    position: 'relative',
                    zIndex: 2
                }}
                variant="caption"
            >
                <Box>
                    <Typography variant="h6"
                                sx={{
                                    fontSize: '135%',
                                    color: date !== "not available" ? "black" : 'lightgrey'
                                }}>{props.title}</Typography>

                    <Typography sx={{
                        fontSize: '100%',
                        fontWeight: !props.date ? 'light' : 'regular',
                        color: date !== "not available" ? "black" : 'lightgrey',
                        fontStyle: date !== "not available" ? "regular" : 'italic'

                    }}>
                        {props.date !== null && (props.title !== "Not Delivered" && props.title !== "For Pickup") &&
                            <Typography variant={"button"}>
                                {date}
                            </Typography>}
                        {props.date !== null && (props.title === "Not Delivered" || props.title === "For Pickup") &&
                            <Typography variant={"body2"} sx={{fontWeight: 'light'}}>
                                {date} (est.)
                            </Typography>}
                    </Typography>
                    {/*{date !== "not available" && props.by && <Typography variant="body"
                                                                         sx={{
                                                                             fontSize: '100%',
                                                                             fontWeight: 'light',
                                                                         }}>{props.by}</Typography>}*/}
                </Box>

            </TimelineContent>
        </Stack>
        {!props.last && <Divider sx={{color: 'black', minWidth: 20}}/>}
    </Stack>
    </>

}

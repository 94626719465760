import React from 'react';
import {Button} from "@mui/material";
import { SnackbarProvider as NSProvider, useSnackbar } from 'notistack';

let useSnackbarRef;
const SnackbarUtilsConfigurator = () => {
    useSnackbarRef = useSnackbar();
    return null;
}

export const SnackbarProvider = ({ children }) => {
    const notistackRef = React.createRef();
    return (
        <NSProvider
            maxSnack={3}
            ref={notistackRef}
            action={(key) => (
                <Button color="inherit" onClick={() => notistackRef.current.closeSnackbar(key)}>
                    Dismiss
                </Button>
            )}
        >
            <SnackbarUtilsConfigurator />
            {children}
        </NSProvider>
    );
};

const toast = {
    success(msg, options) {
        this.toast(msg, 'success', options);
    },
    warning(msg, options) {
        this.toast(msg, 'warning', options);
    },
    info(msg, options) {
        this.toast(msg, 'info', options);
    },
    error(msg, options) {
        this.toast(msg, 'error', options);
    },
    toast(msg, variant = 'default', options) {
        useSnackbarRef.enqueueSnackbar(msg, { variant, ...options });
    },
    close(key) {
        useSnackbarRef.closeSnackbar(key);
    },
};

export default toast;

import {MainWrapper} from './styles';
import React, {Fragment} from 'react';
import {alpha, Link, Stack} from "@mui/material";
import Box from '@mui/material/Box';
import {Formik} from "formik";
import * as yup from "yup";
import MyButton from "../ui/MyButton";
import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router";
import {trackTabChange} from "../utils/reactGA";

const MyTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: alpha("#ffffff", 0.9),
    }, '& label': {
        fontWeight: 2,
        color: alpha("#ffffff", 0.9),
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: "grey",
        border: 1,
    },
    '& .MuiOutlinedInput-root': {
        background: alpha("#000000", 0.4),
        '&.Mui-focused fieldset': {
            borderColor: alpha("#ffffff", 0.9),
        },
        color: 'white'
    },


});

const validationSchema = yup.object({
    token: yup.string().required('Required'),
    zip: yup.string(),
});

export default function Shipping() {

    const initialValues = {
        token: '',
        zip: '',
    }

    const navigate = useNavigate();

    return (<Fragment>

            <MainWrapper>
                <Link to='/'>
                    <div><img style={{position: "absolute", width: 150, top: 20, left: 20}}
                              src={"/LongboardColourLogo.png"} alt={"Logistics App"}/></div>
                </Link>

                <Stack direction={"column"}>

                    <Formik enableReinitialize
                            initialValues={initialValues} validationSchema={validationSchema}

                            onSubmit={(values) => {
                                let finalUrl = '/track/' + values.token;
                                if (values.zip)
                                    finalUrl += '/' + values.zip;
                                navigate(finalUrl)

                                trackTabChange("Logistics/Track Button")

                            }}
                    >
                        {props => (
                            <Stack marginTop={10} direction={"column"} spacing={10} alignItems={"center"}>

                                <Stack direction={"column"} spacing={5}
                                       justifyContent="center"
                                       alignItems="center">
                                    <Box onSubmit={props.handleSubmit}
                                         sx={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                             alignItems: 'center',
                                         }}
                                    >
                                        <Box component="form">
                                            <Stack direction={isMobile ? 'column' : "row"} spacing={2}
                                                   justifyContent={isMobile ? 'center' : "flex-start"}
                                                   alignItems={isMobile ? 'center' : "flex-start"}>
                                                <MyTextField
                                                    size={'small'}
                                                    title={'Sales Order #'}
                                                    id={'token'}
                                                    name={'token'}
                                                    label={'Sales Order #'}
                                                    autoComplete='off'
                                                    required
                                                    value={props.values.token}
                                                    onChange={props.handleChange}
                                                    error={props.touched.token && Boolean(props.errors.token)}
                                                    helperText={props.touched.token && props.errors.token}
                                                />

                                                <MyTextField title={'Zip Code'}
                                                             id={'token'}
                                                             name={'zip'}
                                                             label={'Zip Code'}
                                                             autoComplete='off'
                                                             size={'small'}
                                                             value={props.values.zip}
                                                             onChange={props.handleChange}
                                                             error={props.touched.zip && Boolean(props.errors.zip)}
                                                             helperText={props.touched.zip && props.errors.zip}
                                                />

                                                <MyButton
                                                    type="submit"
                                                    aria-label="save"
                                                >
                                                    Track
                                                </MyButton>
                                            </Stack>

                                        </Box>

                                    </Box>


                                </Stack>
                            </Stack>

                        )}

                    </Formik>

                </Stack>
            </MainWrapper>

        </Fragment>
    );
};

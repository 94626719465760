import React, {useCallback, useState} from 'react';
import {
    Backdrop,
    Card,
    CardMedia,
    CircularProgress,
    Divider,
    Link,
    Paper,
    Snackbar,
    Stack,
    Tab,
    Typography
} from "@mui/material";
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {isMobile} from "react-device-detect";
import {convertTSToDateTime, getMomentDate} from "../utils/getMomentDate";
import moment from "moment";
import ZiggyEvent from "./ZiggyEvent";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Button from "@mui/material/Button";
import DownloadIcon from '@mui/icons-material/Download';
import {Wrapper} from "./styles";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import {trackTabChange} from "../utils/reactGA";

const columns = [

        {field: 'message', type: 'string', disablePadding: false, headerName: 'Note', flex: isMobile ? 2 : 5},

        {
            field: 'ts',
            numeric: false,
            disablePadding: false,
            headerName: 'When',
            flex: 1,
            valueFormatter: convertTSToDateTime,

        },


    ]
;

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export default function Shipment() {

    const [shipment, setShipment] = useState();
    const [error, setError] = useState(false);
    const [errorDownload, setErrorDownload] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, isLoading] = useState(false);

    const {apiUrl} = window['runConfig'];

    let {token, zip} = useParams();

    function isJWT(jwt) {
        let jwtSplitted = jwt.split(".");
        return jwtSplitted.length === 3;
    }

    const loadShipmentFromServer = useCallback(() => {

        let finalUrl = apiUrl + '/track/shipment?token=' + token;

        if (zip)
            finalUrl += '&zip=' + zip;

        finalUrl += `&height=${isMobile ? 200 : 500}&width=${isMobile ? 400 : 1000}`


        axios.get(finalUrl, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            setShipment(response.data);
            setError(false)

            isLoading(false);

        }).catch((error) => {
            setError(true);
            if (error.response?.status === 404) {
                if (isJWT(token))

                    setErrorMessage(`Link is either expired or invalid.`)
                else
                    setErrorMessage(`Sales Order #${token} was not found.`)
            } else {
                setErrorMessage('There was an error processing your request.')
            }
            isLoading(false);

        });


    }, [apiUrl, token, zip]);

    React.useEffect(() => {
        loadShipmentFromServer();
    }, [loadShipmentFromServer]);

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function address() {
        return <Stack direction={"column"} alignItems={isMobile ? 'flex-start' : "flex-end"} spacing={2}>
            {shipment.shipto && <Stack direction="row">

                <Box textAlign={isMobile ? 'left' : 'right'}>
                    <Typography variant={'h6'}> Ship To</Typography>
                    <Box margin={1}></Box>
                    <Typography sx={{fontWeight: 'light'}}>{shipment.shipto.contact}</Typography>
                    <Box margin={1}></Box>


                    <Typography>{shipment.shipto.business}</Typography>

                    <Box margin={1}></Box>
                    <Typography sx={{fontWeight: 'light'}}>{shipment.shipto.addr1}</Typography>
                    <Typography sx={{fontWeight: 'light'}}
                    > {shipment.shipto.city} {shipment.shipto.state} {shipment.shipto.zip}</Typography>
                    <Typography sx={{fontWeight: 'light'}}>{shipment.shipto.country}</Typography>


                </Box>

            </Stack>}
        </Stack>
    }

    function goBackMessage() {
        return <Stack direction={isMobile ? 'column' : "row"} justifyContent="center" alignItems="center" spacing={1}
                      marginTop={10}>
            <Typography align={"center"} color={"#cccccc"}>{errorMessage}</Typography>
            <Link underline="hover"
                  sx={{
                      "&:hover": {
                          cursor: "pointer",
                      }

                  }}
                  onClick={() => goBack()}>
                {'GO BACK'}
            </Link>
        </Stack>
    }

    function timeBetween(date1, date2) {

        const diff = moment.utc(new Date(moment.unix(date2))).diff(moment.utc(new Date(moment.unix(date1))), 'days');
        if (date1 === null || date2 === null || diff === 0) {
            return "";
        }
        return "(" + diff + " days later)"
    }

    const navigate = useNavigate();

    function goBack() {
        navigate('/')
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorDownload(false)
    };

    const handleDownloadPackingSlip = useCallback(() => {

        axios.get(apiUrl + '/track/packing/' + shipment.packingslip_token, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
            responseType: 'blob', // important
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${shipment.salesorderid} - Packing Slip.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                trackTabChange("Logistics/Packing Slip Download Button")

            })
            .catch((error) => {
                setErrorDownload(true)
            })

    }, [shipment, apiUrl]);

    const handleDownloadSignedBOL = useCallback(() => {

        axios.get(apiUrl + '/track/bol/' + shipment.billoflading_token, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
            responseType: 'blob', // important
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${shipment.salesorderid} - Bill of Lading.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();

            })
            .catch((error) => {
                setErrorDownload(true)
            })

    }, [shipment, apiUrl]);

    function content() {

        return (<Box paddingLeft={isMobile ? 0 : 25} paddingRight={isMobile ? 0 : 25}>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>

                {shipment && <Card>

                    <CardMedia
                        component="img"
                        width="100%"
                        height={150}
                        image={shipment.shipto.url_map}
                        alt="Map"
                        sx={{filter: isMobile ? 'brightness(70%)' : ''}}
                    />
                    <div>

                        <Link href='/'>
                            <div><img style={{position: "absolute", width: 150, top: 20, left: 20}}
                                      src={"/LongboardColourLogo.png"} alt={"Logistics App"}/></div>
                        </Link>

                        <Box margin={2}>
                            <div style={{position: "absolute", top: 20, right: 20}}>
                                <Link underline="hover"
                                      sx={{
                                          "&:hover": {
                                              cursor: "pointer",
                                          }

                                      }}
                                      onClick={() => goBack()}>
                                    <CloseIcon sx={{color: '#FFFFFF'}}/>
                                </Link>
                            </div>

                            <Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row',
                                    WebkitOverflowScrolling: 'touch',
                                    // Hide scrollbar
                                    WebkitScrollbar: {display: 'none'},
                                    msOverflowStyle: 'none',
                                    scrollbarWidth: 'none',
                                    justifyContent: 'center'
                                }}>

                                    <ZiggyEvent title={"Ordered"}
                                                date={shipment.dt_ordered}><ShoppingBagIcon
                                        fontSize="small"/></ZiggyEvent>
                                    {!shipment.dt_shipped && shipment.dt_pickup_est && !shipment.dt_delivery ?
                                        <ZiggyEvent title={"For Pickup"}
                                                    date={shipment.dt_pickup_est}><AssignmentTurnedInOutlinedIcon
                                            fontSize="small"/></ZiggyEvent> :
                                        <ZiggyEvent title={"In Transit"}
                                                    by={timeBetween(shipment.dt_ordered, shipment.dt_shipped)}
                                                    date={shipment.dt_shipped}><LocalShippingIcon
                                            fontSize="small"/></ZiggyEvent>
                                    }

                                    {shipment.dt_delivery ?
                                        <ZiggyEvent title={"Delivered"} last
                                                    by={timeBetween(shipment.dt_shipped, shipment.dt_delivery)}

                                                    date={shipment.dt_delivery}><AssignmentTurnedInOutlinedIcon
                                            fontSize="small"/></ZiggyEvent>
                                        :
                                        <ZiggyEvent title={"Not Delivered"} last
                                                    by={timeBetween(shipment.dt_shipped, shipment.dt_delivery)}

                                                    date={shipment.dt_delivery_est}><AssignmentTurnedInOutlinedIcon
                                            fontSize="small"/></ZiggyEvent>
                                    }


                                </Box>
                                <Box margin={1}></Box>
                                <Divider/>
                                <Box margin={2}></Box>
                                <Stack direction={isMobile ? 'column' : 'row'} spacing={1}
                                       justifyContent={'space-between'}
                                       alignItems={isMobile ? 'flex-start' : 'center'}>
                                    <Typography variant={isMobile ? 'h5' : "h4"} component="div">
                                        #{shipment.salesorderid}
                                    </Typography>
                                    <Stack alignItems={isMobile ? 'flex-start' : 'flex-end'}>
                                        {shipment.shipto.residential &&
                                            <Typography style={{color: "rgba(245,124,0,0.56)"}}>RESIDENTIAL
                                                DELIVERY</Typography>}
                                        {shipment.courier && shipment.courier.length > 0 &&

                                            <Typography>
                                                by {shipment.courier.toUpperCase()}
                                            </Typography>
                                        }
                                        {shipment.trackingid && (shipment.trackingurl ?
                                            <Link variant="body2" href={shipment.trackingurl} onClick={() => trackTabChange("Logistics/Tracking Number link clicked")}>{shipment.trackingid}</Link> :
                                            <Typography variant="body2">{shipment.trackingid}</Typography>)}
                                    </Stack>
                                </Stack>
                                <Box margin={2}></Box>
                                <Divider/>
                                <Box margin={2}></Box>

                                <Grid container
                                      alignItems={isMobile ? 'center' : "flex-start"}
                                      justifyContent={"space-between"}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Stack direction={"column"} spacing={2}>
                                            <Stack direction={"column"}>
                                                <Stack spacing={isMobile ? 0 : 2} direction={'column'}
                                                       justifyContent={'flex-start'}
                                                       alignItems={"flex-start"}>

                                                    <Box>


                                                        {shipment.po && shipment.po.length > 0 && <Box>
                                                            <Typography style={{marginRight: "2vh"}} variant="button"
                                                                        display="inline"
                                                                        component="div" sx={{fontSize: '102%'}}>
                                                                PO
                                                            </Typography>
                                                            <Typography display="inline" sx={{fontWeight: 'light'}}
                                                                        component="div">
                                                                {shipment.po}
                                                            </Typography>
                                                        </Box>}

                                                        {shipment.customer && <Box>
                                                            <Typography style={{marginRight: "2vh"}} variant="button"
                                                                        display="inline"
                                                                        component="div" sx={{fontSize: '102%'}}>
                                                                Customer
                                                            </Typography>
                                                            <Typography display="inline" sx={{fontWeight: 'light'}}
                                                                        component="div">
                                                                {shipment.customer}
                                                            </Typography>

                                                        </Box>}
                                                        {shipment.project && <Box>
                                                            <Typography style={{marginRight: "2vh"}} variant="button"
                                                                        display="inline"
                                                                        component="div" sx={{fontSize: '102%'}}>
                                                                Project
                                                            </Typography>
                                                            <Typography display="inline" sx={{fontWeight: 'light'}}
                                                                        component="div">
                                                                {shipment.project}
                                                            </Typography>

                                                        </Box>}
                                                        {shipment.business && <Box>
                                                            <Typography style={{marginRight: "2vh"}} variant="button"
                                                                        display="inline"
                                                                        component="div" sx={{fontSize: '102%'}}>
                                                                Business
                                                            </Typography>
                                                            <Typography display="inline" sx={{fontWeight: 'light'}}
                                                                        component="div">
                                                                {shipment.business}
                                                            </Typography>

                                                        </Box>}


                                                    </Box>


                                                    <Stack direction={'column'}
                                                           spacing={2}>{!isMobile && shipment.packingslip_token &&
                                                        <Button variant={"outlined"} onClick={handleDownloadPackingSlip}
                                                                startIcon={<DownloadIcon/>}>Packing
                                                            Slip</Button>}
                                                        {!isMobile && shipment.billoflading_token &&
                                                            <Button variant={"outlined"}
                                                                    onClick={handleDownloadSignedBOL}
                                                                    startIcon={<DownloadIcon/>}>Bill of Lading
                                                            </Button>}
                                                    </Stack>
                                                </Stack>
                                            </Stack>

                                            {isMobile && <><Divider/>{address()}<Box margin={3}></Box></>}

                                        </Stack>

                                        <Stack direction={'column'} spacing={2}>
                                            {isMobile && shipment.packingslip_token &&
                                                <Button variant={"outlined"} startIcon={<DownloadIcon/>}
                                                        onClick={handleDownloadPackingSlip}>Packing
                                                    Slip</Button>}
                                            {isMobile && shipment.billoflading_token &&
                                                <Button variant={"outlined"} onClick={handleDownloadSignedBOL}
                                                        startIcon={<DownloadIcon/>}>Bill of Lading
                                                </Button>}
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Stack spacing={3} justifyContent={"flex-end"} alignItems={"flex-end"}>
                                            {!isMobile && address()}

                                        </Stack>
                                    </Grid>
                                </Grid>

                                {(shipment.notes.length > 0 || shipment.photos.length > 0) &&
                                    <Box marginTop={4}>
                                        <Paper variant={"outlined"}>
                                            <TabContext value={value}>
                                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                                    <TabList
                                                        onChange={handleChange} aria-label="lab API tabs example"
                                                        value={1}>

                                                        {shipment.notes.length > 0 &&
                                                            <Tab label="Notes" value="1"/>}
                                                        {shipment.photos.length > 0 &&
                                                            <Tab label="Photos"
                                                                 value={shipment.notes.length > 0 ? "2" : "1"}/>}

                                                    </TabList>
                                                </Box>

                                                {shipment.notes.length > 0 && <TabPanel value={"1"}>
                                                    <Box>
                                                        <DataGrid
                                                            rows={shipment.notes}
                                                            columns={columns}
                                                            getRowId={row => row.ordinal}
                                                            autoHeight
                                                            hideFooter
                                                            disableColumnMenu
                                                            disableColumnFilter
                                                            disableColumnSelector
                                                        />
                                                    </Box>
                                                </TabPanel>}
                                                {shipment.photos.length > 0 &&
                                                    <TabPanel value={shipment.photos ? "2" : "1"}>
                                                        <Typography>Soon.</Typography>

                                                    </TabPanel>}
                                            </TabContext>
                                        </Paper>
                                    </Box>}
                            </Box>
                        </Box>
                    </div>


                </Card>}
                {error && <>
                    {isMobile ? <Wrapper>{goBackMessage()}
                    </Wrapper> : goBackMessage()}
                </>
                }
                {error &&

                    <Snackbar open={errorDownload} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                            There was an error processing your request.
                        </Alert>
                    </Snackbar>

                }
            </Box>
        );
    }

    return isMobile ? content() : <Wrapper>{content()}</Wrapper>

}

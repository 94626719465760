import moment from "moment";

export function getMomentDate(params) {
    return params.value !== null ? moment.utc(moment.unix(+params.value)).isSame(moment(), 'day') ? "today" : moment.utc(moment.unix(+params.value)).fromNow() : "";
}

export function convertTSToDateTime(params) {
    if (params.value <= 0)
        return ''
    return moment.unix(+params.value).format('MMM DD YYYY, hh:mm A')
}
import React from 'react';

import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';

export default function MyButton({children, ...props}) {


    const ColorButton = styled(Button) (({theme}) => ({
        color: theme.palette.getContrastText('#464d4d'),
        backgroundColor: '#2f2f2f',
        '&:hover': {
            backgroundColor: '#181818',
        },
    }));

    return (
        <ColorButton {...props} variant="contained">{children}</ColorButton>

    );
}

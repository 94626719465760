import Grid from '@mui/material/Grid';
import React from "react";


export default function CenteredBox(props) {
    return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: '80vh'}}
    >
        <Grid item xs='auto'>
            {props.children}
        </Grid>
    </Grid>
}
import {BrowserRouter} from 'react-router-dom';
import MyRoutes from "./routes";
import ErrorHandler from "./libs/ErrorHandler";
import {LiveUpdateProvider} from "./libs/liveUpdate";
import {SnackbarProvider} from "./libs/toast";

export default function App() {
    return (
        <BrowserRouter>
            <ErrorHandler>
                <SnackbarProvider>
                    <LiveUpdateProvider>
                        <MyRoutes/>
                    </LiveUpdateProvider>
                </SnackbarProvider>
            </ErrorHandler>
        </BrowserRouter>

    );
}

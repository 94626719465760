import {useEffect, useRef, useState} from 'react';

const updateCheckInterval = 5 * 60 * 1000; // 5 mins
// const pauseDuration = 60 * 60 * 1000; // 1hr

// wrapper for the provider
export const LiveUpdateProvider = ({children}) => {
    const [newVersionAvailable, setNewVersionAvailable] = useState(false);
    // const [paused, activatePaused] = useAutoToggleState(pauseDuration);
    const swRegRef = useRef();
    const needActivate = newVersionAvailable/* && !paused*/;

    // check for sw updates
    useEffect(() => {
        navigator.serviceWorker
            .getRegistrations()
            .then((regs) => regs.forEach((reg) => {
                if (reg.waiting) {
                    setNewVersionAvailable(true);
                    swRegRef.current = reg;
                }
            }));
    }, []);

    // trigger sw update check
    useEffect(() => {
        /*if (paused) {
            return;
        }*/

        const checkSWUpdates = () => navigator.serviceWorker
            .getRegistrations()
            .then((regs) => regs.forEach((reg) => reg.update()));

        checkSWUpdates();
        const timer = setInterval(checkSWUpdates, updateCheckInterval);
        return () => {
            clearInterval(timer);
        };
    }, []);

    // listen sw updates
    useEffect(() => {
        const serviceWorkerUpdateHandler = e => {
            setNewVersionAvailable(true);
            swRegRef.current = e.detail;
        };

        document.addEventListener('serviceWorkerUpdate', serviceWorkerUpdateHandler);
        return () => {
            document.removeEventListener('serviceWorkerUpdate', serviceWorkerUpdateHandler);
        };
    }, []);

    /*useEffect(() => {
        try {
            const registrationWaiting = swRegRef.current.waiting;
            if (registrationWaiting) {
                registrationWaiting.postMessage({type: 'SKIP_WAITING'});
                registrationWaiting.addEventListener('statechange', e => {
                    if (e.target.state === 'activated') {
                        window.location.reload();
                    }
                });
            }
        } catch (e) {
        }
    }, []);*/

    useEffect(() => {
        if (!needActivate) {
            return;
        }
        const registrationWaiting = swRegRef.current.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({type: 'SKIP_WAITING'});
            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }, [needActivate]);

    return children;
};

import styled from 'styled-components';
import bgImg from './DSC03135.jpg';

export const MainWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: fixed;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background: url(${bgImg}) center no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.25);
`;

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: auto;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImg}) center no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.25);
  padding: 20px;
`;

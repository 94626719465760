import React from "react";
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import {ErrorBoundary} from "react-error-boundary";
import CenteredBox from "../ui/CenteredBox";

export default function ErrorHandler(props) {

    function ErrorFallback({error, resetErrorBoundary}) {

        return (

            <CenteredBox>
                <Stack direction={"column"} spacing={2} alignItems={"center"}>
                    <Typography variant={"h6"}>Something went wrong!</Typography>
                    <Typography>{error.message}</Typography>
                    <Button variant={"contained"} size={"small"} startIcon={<RefreshIcon/>}
                            onClick={resetErrorBoundary}>Refresh page</Button>
                    {/* <Button variant={"outlined"} size={"small"} startIcon={<ArrowBackIcon/>}
                            onClick={() => {
                                history.goBack()
                            }}>Go back</Button>*/}
                </Stack>
            </CenteredBox>

        )
    }

    return <ErrorBoundary FallbackComponent={ErrorFallback}
                          onReset={() => {
                              window.location.reload()
                          }}
    >
        {props.children}
    </ErrorBoundary>
};